import React, { useState, useEffect } from "react";
import styles from "./PaymentWidget.module.css";
import Modal from "../../component/Modal";
import Intro from "./Intro";
import InvoiceEntry from "./InvoiceEntry";
import Pay from "./Pay";
import AppContext from "../../store/app-context";
import Dialog from "../../component/Dialog";

function PaymentWidget() {
    const appCtx = React.useContext(AppContext);
    const { step } = appCtx.app;
    return (
        <div className={styles.Container}>
            <Modal>
                {step === 1 && <Intro className={styles.slide} />}
                {step === 2 && <InvoiceEntry className={styles.slide} />}
                {step === 3 && <Pay className={styles.slide} />}
            </Modal>
        </div>
    );
}

export default PaymentWidget;

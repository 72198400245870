import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import AppContext from "../../store/app-context";
// import { WidgetBody, WidgetHeader } from '../../component/Widget';

function FlyerCarousel() {
    const appCtx = React.useContext(AppContext);
    const { eventImgs } = appCtx.app;

    return (
        <div>
            <Carousel showThumbs={false}>
                {eventImgs &&
                    Array.isArray(eventImgs) &&
                    eventImgs.length &&
                    eventImgs.map((e, i) => (
                        <div key={i}>
                            <img src={e} alt={"event flyer img"} height={350} />
                        </div>
                    ))}
            </Carousel>
        </div>
    );
}

export default FlyerCarousel;

export const mobilePayImgs = [
    "https://storage.googleapis.com/paycruiser/partners/orange.png",
    "https://storage.googleapis.com/paycruiser/partners/wave-2.png",
    "https://www.paypalobjects.com/webstatic/icon/pp144.png",
    "https://storage.googleapis.com/paycruiser/partners/cards.png",
    "https://storage.googleapis.com/paycruiser/PAYCRUISER-send.jpg",
    "https://storage.googleapis.com/paycruiser/partners/free.png",
    "https://storage.googleapis.com/paycruiser/partners/express.png",
    "https://upload.wikimedia.org/wikipedia/commons/5/56/Stellar_Symbol.png",
    "/zella.png",
    "/venmo.png",
    "/payment.png",
];

export const mobPayOpts = [
    {
        primaryTxt: "Credit Card",
        // secondaryTxt: "VISA, Mastercard",
        secondaryTxt: "",
        img: mobilePayImgs[3],
        type: "CARD",
        method: "cc",
        disabled: false,
        nonUS: false,
    },
    {
        primaryTxt: "Orange Money",
        // secondaryTxt: "mobile money service of Orange S.A.",
        secondaryTxt: "",
        img: mobilePayImgs[0],
        type: "MOBILE",
        method: "orange",
        disabled: false,
        nonUS: true,
    },
    {
        primaryTxt: "WAVE",
        // secondaryTxt: "Deposit and withdraw at any of our agents",
        secondaryTxt: "",
        img: mobilePayImgs[1],
        type: "MOBILE",
        method: "wave",
        disabled: false,
        nonUS: true,
    },
    {
        primaryTxt: "PayPal",
        // secondaryTxt:
        //     "Faster, safer way to send and receive money or make an online payment.",
        secondaryTxt: "",
        img: mobilePayImgs[2],
        type: "MOBILE",
        method: "paypal",
        disabled: false,
        nonUS: false,
    },
    {
        primaryTxt: "Paycruiser",
        // secondaryTxt: "Receive payments from unbanked customers worldwide.",
        secondaryTxt: "",
        img: mobilePayImgs[4],
        type: "PAYCRUISER",
        method: "paycruiser",
        disabled: true,
        nonUS: true,
    },
    {
        primaryTxt: "Free Money",
        // secondaryTxt: "Mobile money provider",
        secondaryTxt: "",
        img: mobilePayImgs[5],
        type: "MOBILE",
        method: "free",
        disabled: true,
        nonUS: true,
    },
    {
        primaryTxt: "E Money",
        // secondaryTxt: "Mobile money provider",
        secondaryTxt: "",
        img: mobilePayImgs[6],
        type: "MOBILE",
        method: "express",
        disabled: true,
        nonUS: true,
    },
    {
        primaryTxt: "Zellepay",
        // secondaryTxt:
        //     "A fast, safe and easy way to send money to friends and family.",
        secondaryTxt: "",
        img: mobilePayImgs[8],
        type: "MOBILE",
        method: "ZELLE",
        disabled: false,
        nonUS: false,
    },
    {
        primaryTxt: "Venmo",
        // secondaryTxt:
        //     "Venmo is the fast, safe, social way to pay and get paid.",
        secondaryTxt: "",
        img: mobilePayImgs[9],
        type: "MOBILE",
        method: "VENMO",
        disabled: false,
        nonUS: false,
    },
    {
        primaryTxt: "Stable Coin",
        // secondaryTxt: "Mobile money provider",
        secondaryTxt: "",
        img: mobilePayImgs[7],
        type: "MOBILE",
        method: "USDC",
        disabled: true,
        nonUS: false,
    },
    {
        primaryTxt: "ACH",
        // secondaryTxt: "Mobile money provider",
        secondaryTxt: "",
        img: mobilePayImgs[10],
        type: "MOBILE",
        method: "ACH",
        disabled: true,
        nonUS: false,
    },
];

export const testInvoiceData = {
    access_code: "444444",
    description: "Test",
    due_on: null,
    id: "10",
    invoice_amount: "10.00",
    invoice_image_url: null,
    is_success: true,
    merchant: {
        id: 5,
        merchant_id: "PAYCRUISER-TEST",
        email: "test@paycruiser.com",
        avatar: "",
        id: 5,
        is_active: true,
        merchant_id: "PAYCRUISER-TEST",
    },
    name: "Test",
    qr_code_url: null,
    short_url: null,
};

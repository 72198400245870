import React, { useEffect, useState } from "react";
import { WidgetHeader, WidgetBody, WidgetFooter } from "../../component/Widget";
import Button from "../../component/Button";
import styles from "./PaymentWidget.module.css";
import { useTranslation } from "react-i18next";
import "../../Translation/i18n";
import i18n from "../../Translation/i18n";
import AppContext from "../../store/app-context";
import TranslateIcon from "../../component/Icons/Translate";
import Dialog from "../../component/Dialog";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "../../component/List";
import Box from "../../component/Box";
import LanguageIcon from "../../component/Icons/Language";
import EventsPage from "./FlyerCarousel";

const selectStyle = {
    position: "absolute",
    bottom: "15vh",
    right: "1.5rem",
    outline: "none",
    outline: "0",
    boxShadow: "none",
    border: "0!important",
    borderRadius: "10px",
    flex: "1",
    padding: ".5rem",
    color: "#eee",
    cursor: "pointer",
    transform: "scale(0.5)",
};

const imageWarpper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1.5rem",
};

function Intro() {
    const appCtx = React.useContext(AppContext);
    const [showDialog, setShowDialog] = useState(false);
    const { setStep, eventImgs } = appCtx.app;
    const { t } = useTranslation();

    const languageHandler = (value) => {
        setShowDialog(false);
        i18n.changeLanguage(value);
    };

    return (
        <div className="intro">
            <WidgetHeader />
            <WidgetBody>
                <div style={imageWarpper}>
                    <div className={styles.IntroLogo}>
                        {/* <img src={brandLogo} alt="logo" /> */}
                        <img
                            src="intro.png"
                            alt="intro-img"
                            className="intro-img"
                        />
                    </div>
                </div>
                <h1>{t("getPaid")}</h1>
                <br />
                <p style={{ color: "#666" }}>{t("moto")}</p>

                <Button style={selectStyle} onClick={() => setShowDialog(true)}>
                    <LanguageIcon />
                </Button>
                {/* <select style={selectStyle} onClick={languageHandler}>
                    <option value="en-US">English</option>
                    <option value="fr-FR">French</option>
                </select> */}
            </WidgetBody>
            <WidgetFooter>
                <Button color="primary" fullWidth onClick={() => setStep(2)}>
                    {t("continue")}
                </Button>
            </WidgetFooter>

            {showDialog && (
                <Dialog>
                    <h3>Choose your language.</h3>
                    <hr />
                    <ListItem onClick={() => languageHandler("en-US")}>
                        <ListItemButton>
                            <ListItemText primary="English"></ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => languageHandler("fr-FR")}>
                        <ListItemButton>
                            <ListItemText primary="French"></ListItemText>
                        </ListItemButton>
                    </ListItem>
                </Dialog>
            )}
        </div>
    );
}

export default Intro;

import React from "react";
import styles from "./List.module.css";

function ListItemButton({ children, ...rest }) {
    return (
        <div className={styles.ListItemButton} {...rest}>
            {children}
        </div>
    );
}

export default ListItemButton;

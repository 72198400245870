import React, { useState } from "react";
import axios from "axios";
import { WidgetHeader, WidgetBody, WidgetFooter } from "../../component/Widget";
import Button from "../../component/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { mobilePayImgs } from "../../constants/app.constant";
import config from "../../configs";

import { toast, ToastContainer } from "react-toastify";
import Input from "../../component/Input";
import Confirmation from "./Confirmation";
import AppContext from "../../store/app-context";
import Box from "../../component/Box";
import Loader from "../../component/Loader";

export default function MobilePay() {
    const appCtx = React.useContext(AppContext);
    const {
        setStep,
        payment,
        setUser,
        user,
        setToken,
        resetPayment,
        isTesting,
        widgetOpt,
    } = appCtx.app;
    const { primaryTxt: paymentTitle } = payment;

    // Form
    const [mobile, setMobile] = useState(
        widgetOpt?.prefill.user_mobile_no?.value || ""
    );
    const [toggle, setToggle] = useState(false);
    const [isotpSent, setisotpSent] = useState(false);
    const [otp, setotp] = React.useState("");
    const [loading, setLoading] = React.useState(null);
    const [imgSrc, setImgSrc] = React.useState("");

    React.useEffect(() => {
        setImgSrc(payment.img);
    }, [payment]);

    React.useEffect(() => {
        if (isotpSent) {
            setisotpSent(false);
        }
    }, [mobile]);

    const backHandler = () => {
        resetPayment();
        setStep(3);
    };

    const showToast = (msg, type = "info") => {
        toast(msg, {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type,
        });
    };

    const getOTP = () => {
        if (!mobile) {
            showToast("Please enter your mobile no.", "error");
            return;
        }

        if (isTesting) return setisotpSent(true);

        showToast("Please wait..");
        setLoading(true);
        axios({
            method: "post",
            url: `${config.baseUrl}/user/mmo-auth/`,
            data: { phone_number: `+${mobile}` },
        })
            .then((data) => {
                setLoading(false);
                if (!!data?.data?.is_success) {
                    setisotpSent(true);
                    showToast(`${data.data.one_time_pass_result.detail}`);
                } else {
                    showToast(`${data?.data?.errors}`);
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response && err.response?.data) {
                    showToast(`${err.message}`);
                }
            });
    };

    const verifyOTP = () => {
        if (!otp) {
            showToast("Please enter valid OTP", "error");
            return;
        }

        if (isTesting) return setUser({});

        showToast("Please wait...");
        setLoading(true);
        axios
            .post(`${config.baseUrl}/callback/auth/`, {
                token: otp,
                mobile: `+${mobile}`,
            })
            .then(({ data }) => {
                if (data?.is_success) {
                    setLoading(true);
                    return axios
                        .get(`${config.baseUrl}/rest-auth/user/`, {
                            headers: {
                                authorization: `Token ${data.token}`,
                            },
                        })
                        .then((res) => {
                            setLoading(false);
                            setUser(res.data);
                            setToken(data.token);
                            // localStorage.setItem('data', JSON.stringify(data));
                            // localStorage.setItem('res', JSON.stringify(res));
                            // console.log({auth, data});
                            // auth.setToken(data.token);
                            // auth.setUserId(res.data.id);
                            // auth.setMerchantId(res.data.merchant_id);
                            // auth.setUserInfo(res.data);
                            // return { token: data.token };
                        });
                } else {
                    showToast(`${data?.error_msg}`, "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                const msg =
                    (err &&
                        err.response &&
                        err.response?.data &&
                        err.response?.data.token &&
                        err.response?.data.token.length &&
                        err.response?.data.token.join(" ")) ||
                    err.message;
                showToast(`${msg}`, "error");
            });
    };

    return (
        <div className="widget-wrapper-slide-left">
            <WidgetHeader
                isBack={true}
                onBack={backHandler}
                title={paymentTitle}
            />
            {!user && (
                <>
                    <WidgetBody style={{ justifyContent: "flex-start" }}>
                        <ToastContainer />

                        {imgSrc && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContainer: "center",
                                }}
                            >
                                <img
                                    src={imgSrc}
                                    alt="logo"
                                    height={150}
                                    width={150}
                                />
                            </Box>
                        )}
                        <div>
                            <p style={{ margin: "1rem 0" }}>
                                Enter your mobile number to receive a One Time
                                Code
                            </p>
                            <PhoneInput
                                style={{
                                    boxShadow: "3px 5px 15px #082ba824",
                                    borderBottom: "1px solid #777",
                                }}
                                countryCodeEditable={true}
                                inputStyle={{
                                    fontFamily: "Arial,sans-serif",
                                    height: "3rem",
                                    border: "none",
                                }}
                                name="phoneNumber"
                                type="tel"
                                masks={{ ci: ".. .. .. .. .." }}
                                country={"sn"}
                                value={mobile}
                                onChange={setMobile}
                                onFocus={() => setToggle(true)}
                                onBlur={() => setToggle(false)}
                                // Set your inline styles here
                                containerStyle={{
                                    display: "flex",
                                    borderBottom: toggle
                                        ? "1px solid blue"
                                        : "1px solid grey",
                                }}
                                dropdownStyle={{
                                    border: "none",
                                    textAlign: "start",
                                }}
                                buttonStyle={{ border: "none" }}
                                required
                            />
                        </div>

                        {!!isotpSent && (
                            <div style={{ margin: "1rem 0" }}>
                                <Input
                                    type="number"
                                    name="otp"
                                    value={otp}
                                    placeholder="Please enter your OTP"
                                    onChange={(e) => setotp(e.target.value)}
                                />
                                
                                <p>OTP Sent to <b>{mobile} via WHATSAPP (or SMS if located in the USA)</b></p>
                                <p>Did not receive the OTP code? </p>
                                <p>
                                    <span
                                        onClick={getOTP}
                                        style={{
                                            cursor: "pointer",
                                            color: "blue",
                                        }}
                                    >
                                        <strong>Resend</strong>
                                    </span>
                                </p>
                            </div>
                        )}
                    </WidgetBody>

                    <WidgetFooter>
                        {!isotpSent && (
                            <Button
                                color="primary"
                                fullWidth
                                onClick={getOTP}
                                disabled={loading}
                            >
                                {loading ? <Loader color="#fff" /> : "Next"}
                            </Button>
                        )}

                        {isotpSent && (
                            <Button
                                color="primary"
                                fullWidth
                                onClick={verifyOTP}
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader color="#fff" />
                                ) : (
                                    "Verify OTP"
                                )}
                            </Button>
                        )}
                    </WidgetFooter>
                </>
            )}

            {/* If user data loaded then render confirm user payment page */}
            {!!user && <Confirmation />}
        </div>
    );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { WidgetHeader, WidgetBody, WidgetFooter } from "../../component/Widget";
import Button from "../../component/Button";
import Input from "../../component/Input";
import styles from "./PaymentWidget.module.css";
import config from "../../configs";

import Alert from "../../component/Alert";
import AppContext from "../../store/app-context";
import { useTranslation } from "react-i18next";
import { testInvoiceData } from "../../constants/app.constant";
import Loader from "../../component/Loader";
import Dialog from "../../component/Dialog";

function InvoiceEntry() {
    const appCtx = React.useContext(AppContext);
    const [showDialog, setShowDialog] = useState(false);
    const {
        setStep,
        setInvoice,
        widgetOpt,
        isTesting,
        setWidgetOPT,
        brandLogo,
        setEventImgs,
    } = appCtx.app;

    const [invoiceNo, setInvoiceNo] = React.useState("");
    const [invoiceError, setInvoiceError] = React.useState("");
    const [loading, setloading] = React.useState(false);
    const [apiErr, setApiErr] = React.useState("");
    // const { search } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (widgetOpt.security_code) {
            setInvoiceNo(widgetOpt.security_code);
            checkSecurityCode(widgetOpt.security_code);
        }
    }, [widgetOpt.security_code]);

    const setNValidate = (value) => {
        setInvoiceNo(value);
        if (!value) setInvoiceError("This field is required.");
        else {
            setInvoiceError("");
            setApiErr("");
        }
    };

    const nextHandler = (e) => {
        e.preventDefault();
        if (!invoiceNo) {
            setInvoiceError("This field is required.");
            return;
        }

        checkSecurityCode(invoiceNo);
    };

    const checkSecurityCode = (invoiceNo) => {
        if (isTesting) {
            setStep(3);
            setInvoice(testInvoiceData);
            return;
        }

        setloading(true);
        setShowDialog(true);
        axios
            .get(
                `${config.baseUrl}/pay/invoice-lookup/?security_code=${invoiceNo}`,
                {
                    headers: { authorization: "" },
                }
            )
            .then(({ data }) => {
                setloading(false);
                setShowDialog(false);
                if (data.is_success === true) {
                    setInvoice(data);

                    if (data?.invoice_image_url) {
                        if (Array.isArray(data?.invoice_image_url)) {
                            setEventImgs(data?.invoice_image_url);
                        } else {
                            setEventImgs([data?.invoice_image_url]);
                        }
                    }
                    setWidgetOPT({
                        prefill: {
                            amount: {
                                value: data.invoice_amount,
                                disabled: false,
                            },
                        },
                    });
                    setStep(3);
                    setApiErr("");
                } else {
                    setApiErr(data.errors);
                }
            })
            .catch((e) => {
                setloading(false);
                setShowDialog(false);
                setApiErr(e);
            });
    };

    return (
        <div className="widget-wrapper-slide-left">
            <WidgetHeader isBack={true} onBack={() => setStep(1)} />
            <WidgetBody>
                {brandLogo && (
                    <div className={styles.stepInfo}>
                        <img
                            src={brandLogo}
                            alt="logo"
                            className={styles.IntroLogo}
                        />
                    </div>
                )}
                <form style={{ margin: "auto 0" }} onSubmit={nextHandler}>
                    <h2
                        style={{
                            fontWeight: 500,
                            textAlign: "left",
                            lineHeight: 1,
                            marginBottom: "1rem",
                        }}
                    >
                        {t("enter")}{" "}
                    </h2>
                    <Input
                        placeholder={t("invoice")}
                        value={invoiceNo}
                        error={invoiceError}
                        helperText={invoiceError}
                        onBlur={(e) => setNValidate(e.target.value)}
                        onChange={(e) => setNValidate(e.target.value)}
                    />
                </form>
                {apiErr && <Alert severity="error">{apiErr}</Alert>}
            </WidgetBody>
            <WidgetFooter>
                <Button
                    color="primary"
                    fullWidth
                    onClick={nextHandler}
                    disabled={loading}
                >
                    {t("next")}
                </Button>
            </WidgetFooter>

            {showDialog && (
                <Dialog>
                    <h3>Please wait..</h3>
                    <br></br>
                    <p>We are fetching your Invoice details</p>
                    <br></br>
                    <Loader color="#000" />
                </Dialog>
            )}
        </div>
    );
}

export default InvoiceEntry;

import React from "react";
import { createPortal } from "react-dom";
import BackDrop from "../Backdrop";

function Dialog({ children, className = "", handleClose = () => {} }) {
    const elRef = React.useRef(null);
    if (!elRef.current) {
        elRef.current = document.createElement("div");
        elRef.current.classList.add("dialogOverlay");
    }

    React.useEffect(() => {
        const modalElem = document.getElementById("modal-inner");
        elRef.current.addEventListener("click", handleClose);
        modalElem.appendChild(elRef.current);
        return () => {
            elRef.current.removeEventListener("click", handleClose);
            modalElem.removeChild(elRef.current);
        };
    }, []);

    return createPortal(
        <div>
            <div className={`dialogContainer ${className}`}>{children}</div>
        </div>,
        elRef.current
    );
}

export default Dialog;

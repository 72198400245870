import React from "react";
import styles from "./List.module.css";

function ListItemIcon(props) {
    const { children, end, ...others } = props;
    return (
        <div
            className={`${styles.ListItemIcon} ${
                end === true ? styles.iconEnd : ""
            }`}
            {...others}
        >
            {children}
        </div>
    );
}

export default ListItemIcon;

import React from "react";
import styles from "./List.module.css";

function ListItemText(props) {
    return (
        <div className={styles.ListItemText}>
            <p>{props.primary}</p>
            {props.secondary && (
                <small
                    style={{
                        lineHeight: "1.3",
                        marginTop: "0.5rem",
                        display: "inline-block",
                    }}
                >
                    {props.secondary}
                </small>
            )}
        </div>
    );
}

export default ListItemText;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { AppContextProvidor } from "./store/app-context";

const root = document.getElementById("paycruiser-widget");
ReactDOM.render(
    // <React.StrictMode>
    <AppContextProvidor>
        <Router>
            <App />
        </Router>
    </AppContextProvidor>,
    // </React.StrictMode>
    root
);

import React from "react";
import styles from "./List.module.css";

function ListItem({ children, ...rest }) {
    const isDisabled = rest?.disabled || false;
    return (
        <li
            className={`${styles.ListItem} ${
                isDisabled ? styles["userDisable"] : ""
            }`}
            {...rest}
        >
            {children}
        </li>
    );
}

export default ListItem;

import React from "react";
import { WidgetHeader, WidgetBody, WidgetFooter } from "../../component/Widget";
import AppContext from "../../store/app-context";

function PaycruiserPayment() {
    const appCtx = React.useContext(AppContext);
    const { setStep } = appCtx.app;
    return (
        <>
            <WidgetHeader isBack={true} onBack={() => setStep(2)} />
            <WidgetBody headerOnly style={{ display: "block" }}></WidgetBody>
        </>
    );
}

export default PaycruiserPayment;

import React from "react";
import { WidgetHeader, WidgetBody } from "../../component/Widget";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from "../../component/List";
import styles from "./PaymentWidget.module.css";
import AppContext from "../../store/app-context";
import { useTranslation } from "react-i18next";
import { mobPayOpts } from "../../constants/app.constant";
import SheildIcon from "../../component/Icons/Sheild";
import FlyerCarousel from "./FlyerCarousel";

const paymentTitle = {
    textAlign: "left",
    fontWeight: "500",
    color: "rgb(195 68 68 / 95%)",
    marginBottom: "0.5rem",
};

function PaymentChoose() {
    const appCtx = React.useContext(AppContext);
    const [payOpts, setPayOpts] = React.useState(mobPayOpts);
    const {
        setStep,
        payment,
        setPayment,
        invoice: invoiceInfo,
        widgetOpt,
        eventImgs,
    } = appCtx.app;
    const { t } = useTranslation();

    const paymentHandler = (method, title) => {
        setPayment({ method, title });
    };

    React.useEffect(() => {
        if (invoiceInfo?.merchant?.user_currency === "USD") {
            setPayOpts(mobPayOpts.filter((p) => !p.nonUS));
        } else {
            setPayOpts(mobPayOpts);
        }
    }, [invoiceInfo?.merchant?.user_currency]);

    React.useEffect(() => {
        if (widgetOpt.default_payment.length) {
            let filteredPayOpts = [];
            mobPayOpts.map((e) => {
                widgetOpt.default_payment.map((f) => {
                    if (e.method === f) filteredPayOpts.push(e);
                });
            });

            if (filteredPayOpts.length) setPayOpts(filteredPayOpts);
        }
    }, [widgetOpt.default_payment]);

    return (
        <div className="widget-wrapper-slide-left">
            <WidgetHeader isBack={true} onBack={() => setStep(2)} />
            <WidgetBody headerOnly style={{ display: "block" }}>
                <div className={styles.stepInfo}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="80px" viewBox="0 0 24 24" width="80px" fill="#082aa8"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M18.49,9.88l0.08-0.85l0.18-1.95l-1.9-0.43l-0.84-0.19l-0.44-0.74l-0.99-1.68L12.79,4.8L12,5.14L11.21,4.8 L9.42,4.03L8.43,5.71L7.99,6.45L7.15,6.64l-1.9,0.43l0.18,1.94l0.08,0.85l-0.56,0.65l-1.29,1.48l1.29,1.47l0.56,0.65l-0.08,0.85 l-0.18,1.96l1.9,0.43l0.84,0.19l0.44,0.74l0.99,1.67l1.78-0.77L12,18.85l0.79,0.34l1.78,0.77l0.99-1.68l0.44-0.74l0.84-0.19 l1.9-0.43l-0.18-1.95l-0.08-0.85l0.56-0.65l1.29-1.47l-1.29-1.47L18.49,9.88z M10.09,16.72l-3.8-3.81l1.48-1.48l2.32,2.33 l5.85-5.87l1.48,1.48L10.09,16.72z" opacity=".3"/><path d="M23,11.99l-2.44-2.79l0.34-3.69l-3.61-0.82L15.4,1.5L12,2.96L8.6,1.5L6.71,4.69L3.1,5.5L3.44,9.2L1,11.99l2.44,2.79 l-0.34,3.7l3.61,0.82L8.6,22.5l3.4-1.47l3.4,1.46l1.89-3.19l3.61-0.82l-0.34-3.69L23,11.99z M19.05,13.47l-0.56,0.65l0.08,0.85 l0.18,1.95l-1.9,0.43l-0.84,0.19l-0.44,0.74l-0.99,1.68l-1.78-0.77L12,18.85l-0.79,0.34l-1.78,0.77l-0.99-1.67l-0.44-0.74 l-0.84-0.19l-1.9-0.43l0.18-1.96l0.08-0.85l-0.56-0.65l-1.29-1.47l1.29-1.48l0.56-0.65L5.43,9.01L5.30,7.07l1.9-0.43l0.84-0.19 l0.44-0.74l0.99-1.68l1.78,0.77L12,5.14l0.79-0.34l1.78-0.77l0.99,1.68l0.44,0.74l0.84,0.19l1.9,0.43l-0.18,1.95l-0.08,0.85 l0.56,0.65l1.29,1.47L19.05,13.47z"/><polygon points="10.09,13.75 7.77,11.42 6.29,12.91 10.09,16.72 17.43,9.36 15.95,7.87"/></g></g></svg> */}

                    {eventImgs && !!eventImgs.length && (
                        // <FlyerCarousel />
                        <img src={eventImgs[0]} alt="Flyer" height={350} />
                    )}
                    <div style={{ marginTop: "1rem" }}>
                        {eventImgs && !eventImgs.length && (
                            <>
                                <SheildIcon />
                                <h2
                                    style={{
                                        fontWeight: "500",
                                        lineHeight: 1.1,
                                    }}
                                >
                                    {t("secure")}
                                </h2>
                            </>
                        )}

                        <em
                            style={{
                                color: "#777",
                                lineHeight: 1,
                                display: "block",
                            }}
                        >
                            {t("never")}
                        </em>

                        <h3 style={{ fontWeight: "500", marginTop: "0.5rem" }}>
                            {invoiceInfo?.merchant?.merchant_id}{" "}
                            <span
                                style={{ fontSize: "1rem", fontWeight: "400" }}
                            >
                                {invoiceInfo?.description}
                            </span>
                        </h3>
                    </div>
                </div>

                <div></div>

                <List>
                    <h3 style={paymentTitle}> {t("howPay")}</h3>

                    {payOpts.map((e) => (
                        <ListItem
                            disabled={e.disabled}
                            key={e.method}
                            onClick={() => setPayment(e)}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <img
                                        src={e.img}
                                        alt="logo"
                                        height="30"
                                        width="30"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={e.primaryTxt}
                                    secondary={e.secondaryTxt}
                                ></ListItemText>
                                <ListItemIcon end={true}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="#777"
                                    >
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                    </svg>
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    ))}

                    {/* Orange Money */}
                    {/* <ListItem>
                        <ListItemButton
                            onClick={() =>
                                paymentHandler("MOBILE", "Orange Money")
                            }
                        >
                            <ListItemIcon>
                                <img
                                    src="https://storage.googleapis.com/paycruiser/partners/orange.png"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Orange Money"
                                secondary="Mobile Money Provider"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* WAVE */}
                    {/* <ListItem>
                        <ListItemButton
                            onClick={() => paymentHandler("MOBILE", "WAVE")}
                        >
                            <ListItemIcon>
                                <img
                                    src="https://storage.googleapis.com/paycruiser/partners/wave-2.png"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="WAVE"
                                secondary="Mobile Money Provider"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* Credit Card */}
                    {/* <ListItem>
                        <ListItemButton
                            onClick={() =>
                                paymentHandler("CREDIT", "Credit Card")
                            }
                        >
                            <ListItemIcon>
                                <img
                                    src="https://storage.googleapis.com/paycruiser/partners/cards.png"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Credit Card"
                                secondary="Visa, MasterCard"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* Paycruiser */}
                    {/* <ListItem disabled={true}>
                        <ListItemButton
                            onClick={() =>
                                paymentHandler("PAYCRUISER", "Paycruiser")
                            }
                        >
                            <ListItemIcon>
                                <img
                                    src="https://storage.googleapis.com/paycruiser/PAYCRUISER-send.jpg"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Paycruiser"
                                secondary="Pay with paycruiser"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* Free Money */}
                    {/* <ListItem disabled={true}>
                        <ListItemButton
                            onClick={() =>
                                paymentHandler("MOBILE", "Free Money")
                            }
                        >
                            <ListItemIcon>
                                <img
                                    src="https://storage.googleapis.com/paycruiser/partners/free.png"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Free Money"
                                secondary="Mobile Money Provider"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* Express Money */}
                    {/* <ListItem disabled={true}>
                        <ListItemButton
                            onClick={() =>
                                paymentHandler("MOBILE", "Express Money")
                            }
                        >
                            <ListItemIcon>
                                <img
                                    src="https://storage.googleapis.com/paycruiser/partners/express.png"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="E Money"
                                secondary="Mobile Money Provider"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* USDC */}
                    {/* <ListItem disabled={true}>
                        <ListItemButton
                            onClick={() => paymentHandler("MOBILE", "USDC")}
                        >
                            <ListItemIcon>
                                <img
                                    src="https://getcrypto.info/images/logos/stellar_lumens.png"
                                    alt="logo"
                                    height="30"
                                    width="30"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Stable Coin"
                                secondary="USDC Stable Coin via Stellar Network"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* Cheque */}
                    {/* <ListItem disabled={true}>
                        <ListItemIcon style={{ justifyContent: "center" }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#777"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-9 8H3V9h9v2zm0-4H3V5h9v2z" />
                            </svg>
                        </ListItemIcon>
                        <ListItemButton>
                            <ListItemText
                                primary="Cheque"
                                secondary="ACH/Wire Transfer"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}

                    {/* Cash */}
                    {/* <ListItem disabled={true}>
                        <ListItemIcon style={{ justifyContent: "center" }}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="#777"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
                            </svg>
                        </ListItemIcon>
                        <ListItemButton>
                            <ListItemText
                                primary="Cash"
                                secondary="Via Nearby Grocery Stores"
                            ></ListItemText>
                            <ListItemIcon end={true}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#777"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                                </svg>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </WidgetBody>
        </div>
    );
}

export default PaymentChoose;

import React, { useEffect } from "react";
import Alert from "../../component/Alert";
import Input from "../../component/Input";
import { mobilePayImgs } from "../../constants/app.constant";
import config from "../../configs";
import axios from "axios";
import { WidgetBody, WidgetFooter } from "../../component/Widget";
import Button from "../../component/Button";
import { toast, ToastContainer } from "react-toastify";
import AppContext from "../../store/app-context";
import Box from "../../component/Box";
import Loader from "../../component/Loader";

function Confirmation(props) {
    const appCtx = React.useContext(AppContext);
    const {
        setStep,
        payment,
        invoice: invoiceInfo,
        user,
        token,
        reset,
        isTesting,
        widgetOpt,
    } = appCtx.app;
    const { primaryTxt: paymentTitle } = payment;
    const { invoice_amount, access_code, merchant } = invoiceInfo;
    let timer;
    const toast_id = React.useRef(null);

    // Form
    const [amount, setAmount] = React.useState(
        parseFloat(
            widgetOpt.prefill.amount
                ? widgetOpt.prefill.amount.value
                : invoice_amount
        ) || 0
    );
    const [loading, setLoading] = React.useState(0);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogContent, setDialogContent] = React.useState("");
    const [isDialogShown, setDialogShown] = React.useState(false);
    const [client_paid_on_phone, setclient_paid_on_phone] =
        React.useState(false);
    const [imgSrc, setImgSrc] = React.useState("");

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);

    React.useEffect(() => {
        setImgSrc(payment.img);
    }, []);

    const handleSubmit = async () => {
        if (isTesting) {
            setclient_paid_on_phone(true);
            return;
        }

        if (loading) return;
        setLoading(true);
        const userInfo = user;
        let network_carrier = "";
        const title = paymentTitle.toString().toLowerCase();

        if (title.includes("orange")) network_carrier = "OM";
        if (title.includes("free")) network_carrier = "FREE";
        if (title.includes("wave")) network_carrier = "WAVE";
        if (title.includes("emoney")) network_carrier = "EXPRESSO";
        if (title.includes("paypal")) network_carrier = "PAYPAL";

        const payload = {
            mobile_number_to_charge: userInfo.username,
            client_fullname: userInfo.first_name + " " + userInfo.last_name,
            client_email: merchant.email,
            transaction_reference: merchant.merchant_id,
            amount_to_charge_in_cents: amount * 100,
            // currency_code: "XOF",
            // country_code: "SN",
            network_carrier,
            customer_ref: access_code,
        };
        makePayment(payload);
    };

    const makePayment = (payload) => {
        axios
            .post(`${config.baseUrl}/mobile-money/charge/`, payload, {
                headers: { authorization: `Token ${token}` },
            })
            .then((res) => {
                if (res.data.errors) {
                    showToast(`${res.data.errors}`, "error");
                }

                if (Array.isArray(res.data.errors) && res.data.errors.length) {
                    setDialogContent(res.data.errors);
                } else {
                    setDialogContent(res.data.message);
                }

                // TODO: Use constant value for transaction status
                if (res.data.customer_successfully_paid === 1) {
                    // Success or Paid
                    setDialogTitle("Initiation du paiement en cours");
                    setLoading(false);
                    setDialogShown(false);
                } else if (res.data.customer_successfully_paid === 0) {
                    // pending
                    setLoading(true);
                    setDialogShown(true);
                    finalizeMobileMoneyPayment(res.data.id);
                    showToast(`${res.data.message}`);
                    //const id = toast.loading("Waiting for customer payment.");
                    toast_id.current = toast.loading(
                        "Waiting for customer to complete the payment..."
                    );
                } else if (res.data.customer_successfully_paid === 2) {
                    // faild
                    showToast(`${res.data.message}`);
                    setLoading(false);
                } else {
                    setDialogTitle(
                        `${res.data.message} (${res.data.transaction_status})`
                    );
                    setLoading(false);
                    setDialogShown(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showToast = (msg, type = "info", autoClose = 6000) => {
        toast(msg, {
            position: "bottom-center",
            autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type,
        });
    };

    const finalizeMobileMoneyPayment = (transaction_id) => {
        axios
            .get(
                `${config.baseUrl}/mobile-money/charge/` + transaction_id + `/`,
                {
                    headers: { authorization: `Token ${token}` },
                }
            )
            .then(async (data) => {
                //dispatch action to redux store
                if (data.data.customer_successfully_paid === 1) {
                    toast.update(toast_id.current, {
                        render: "Transaction is successful",
                        type: "success",
                        isLoading: false,
                        theme: "colored",
                    });
                    setclient_paid_on_phone(true);
                } else if (data.data.transaction_status === "failed") {
                    toast.update(toast_id.current, {
                        render: "Transaction failed",
                        type: "error",
                        isLoading: false,
                        theme: "colored",
                    });
                    loading(false);
                } else {
                    timer = setTimeout(() => {
                        finalizeMobileMoneyPayment(transaction_id, toast);
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.update(toast_id.current, {
                    render: "Failed to check payment status.",
                    type: "error",
                    isLoading: false,
                    theme: "colored",
                });
            });
    };

    return (
        <div className="widget-wrapper-slide-left">
            <WidgetBody>
                <ToastContainer />
                {imgSrc && (
                    <Box sx={{ display: "flex", justifyContainer: "center" }}>
                        <img src={imgSrc} alt="logo" height={150} width={150} />
                    </Box>
                )}

                {!client_paid_on_phone && (
                    <>
                        <Alert severity="success">
                            You are going to make a payment of{" "}
                            {parseFloat(amount)}{" "}
                            {merchant?.user_currency
                                ? merchant?.user_currency
                                : "N/A"}
                        </Alert>
                        <Input
                            style={{ margin: "1rem 0" }}
                            type="number"
                            name="numberformat"
                            placeholder="Montant à payer"
                            value={amount}
                            disabled={
                                widgetOpt?.prefill.amount.disabled || false
                            }
                            onChange={(e) => setAmount(e.target.value)}
                            label="Montant à payer (pas de decimal)"
                        />

                        <p>
                            En cliquant sur SUIVANT, vous acceptez d'envoyer une
                            requette de prelèvement de{" "}
                            {`${amount} ${
                                merchant?.user_currency
                                    ? merchant?.user_currency
                                    : "N/A"
                            } `}
                            pour chez {merchant.merchant_id}
                        </p>
                    </>
                )}

                {client_paid_on_phone && (
                    <>
                        <img
                            height={100}
                            width={100}
                            src="https://storage.googleapis.com/paycruiser/PayCruiser_Success.gif"
                            alt="success gif"
                            style={{ padding: "5rem" }}
                        />
                        <h2 style={{ color: "#555" }}>
                            Congratulations!! You have successfully paid.
                        </h2>
                    </>
                )}
            </WidgetBody>

            <WidgetFooter>
                {/* <ToastContainer /> */}
                {!client_paid_on_phone && (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? (
                            <Loader color="#fff" />
                        ) : (
                            `Pay ${amount} ${
                                merchant?.user_currency
                                    ? merchant?.user_currency
                                    : ""
                            }`
                        )}
                    </Button>
                )}
                {client_paid_on_phone && (
                    <Button color="primary" fullWidth onClick={() => reset()}>
                        Make New Payment
                    </Button>
                )}
            </WidgetFooter>
        </div>
    );
}

export default Confirmation;

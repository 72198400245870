import React from "react";
import styles from "./List.module.css";
import ListItem from "./ListItem";
import ListItemButton from "./ListItemButton";
import ListItemIcon from "./ListItemIcon";
import ListItemText from "./ListItemText";

function List(props) {
    return (
        <nav>
            <ul className={styles.List}>{props.children}</ul>
        </nav>
    );
}

export { List, ListItem, ListItemButton, ListItemIcon, ListItemText };

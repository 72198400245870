import React from "react";
import BackDrop from "../component/Backdrop";

const style = {
    textDecoration: "none",
    background: "rgb(214, 68, 68)",
    border: "1px dashed white",
    padding: "3px",
    opacity: "1",
    transform: "rotate(45deg)",
    transition: "opacity 0.3s ease-in 0s",
    color: "white",
    position: "absolute",
    width: "200px",
    textAlign: "center",
    right: "-50px",
    top: "50px",
};

function TestMode() {
    return (
        <BackDrop>
            <span style={style}>Test Mode</span>
        </BackDrop>
    );
}

export default TestMode;

import React from "react";

function TranslateIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 -960 960 960"
            width="48"
        >
            <path d="m475-80 181-480h82L924-80h-87l-41-126H604L557-80h-82Zm151-196h142l-70-194h-2l-70 194Zm-466 76-55-55 204-204q-38-44-67.5-88.5T190-640h87q17 33 37.5 62.5T361-517q45-47 75-97.5T487-720H40v-80h280v-80h80v80h280v80H567q-22 69-58.5 135.5T419-458l98 99-30 81-127-122-200 200Z" />
        </svg>
    );
}

export default TranslateIcon;

import React from "react";
import styles from "./WidgetBody.module.css";

function WidgetBody(props) {
    const { children, headerOnly, footerOnly, ...others } = props;
    return (
        <div
            className={`
        ${styles.widgetBody} 
        ${headerOnly ? styles.headerOnly : ""} 
        ${footerOnly ? styles.footerOnly : ""}
      `}
            {...others}
        >
            {children}
        </div>
    );
}

export default WidgetBody;

import React, { useContext, useReducer } from "react";

const initWidgetOpt = {
    invoice_no: "",
    security_code: "",
    hasBackdrop: true,
    isMobile: true,
    mechant_name: "",
    testing: false,
    default_payment: [],
    prefill: {
        amount: { value: "", disabled: false },
        user_first_name: { value: "", disabled: false },
        user_last_name: { value: "", disabled: false },
        user_mobile_no: { value: "", disabled: false },
        user_card_no: { value: "", disabled: false },
        user_card_exp_yr: { value: "", disabled: false },
        user_card_exp_m: { value: "", disabled: false },
        user_card_cvv: { value: "", disabled: false },
        user_currency: "XOF",
    },
};

const initAppData = {
    env: process.env.NODE_ENV,
    theme: "light",
    step: 1,
    brandLogo: "https://storage.googleapis.com/paycruiser/PAYCRUISER-send.jpg",
    eventImgs: [],
    invoice: null,
    user: null,
    token: "",
    isTesting: false,
    widgetOpt: initWidgetOpt,
    payment: {
        type: "",
        status: 0,
    },
};

const appReducer = (prevState, action) => {
    if (action.type === "SET_STEP") {
        return { ...prevState, step: action.data };
    } else if (action.type === "SET_INVOICE") {
        return { ...prevState, invoice: action.data };
    } else if (action.type === "SET_USER") {
        return { ...prevState, user: action.data };
    } else if (action.type === "SET_TOKEN") {
        return { ...prevState, token: action.data };
    } else if (action.type === "RESET_PAYEMENT") {
        return {
            ...prevState,
            payment: {
                type: "",
                status: 0,
            },
        };
    } else if (action.type === "SET_PAYEMENT") {
        return {
            ...prevState,
            payment: { ...prevState.payment, ...action.data },
        };
    } else if (action.type === "SET_LOGO") {
        return { ...prevState, brandLogo: action.data };
    } else if (action.type === "SET_EVENT_IMGS") {
        return { ...prevState, eventImgs: action.data };
    } else if (action.type === "SET_ENV") {
        return {
            ...prevState,
            env: action.data,
            isTesting: action.data === "test",
        };
    } else if (action.type === "SET_WIDGET_OPT") {
        return {
            ...prevState,
            widgetOpt: {
                ...prevState.widgetOpt,
                ...action.data,
                prefill: {
                    ...prevState.widgetOpt.prefill,
                    ...action.data.prefill,
                },
            },
        };
    } else if (action.type === "RESET") {
        return { ...prevState, ...action.data };
    } else return initAppData;
};

const AppContext = React.createContext(initAppData);

export const AppContextProvidor = (props) => {
    const [appState, appDispatch] = useReducer(appReducer, initAppData);

    const setStep = (data) => {
        appDispatch({ type: "SET_STEP", data });
    };

    const setInvoice = (data) => {
        appDispatch({ type: "SET_INVOICE", data });
    };

    const setUser = (data) => {
        appDispatch({ type: "SET_USER", data });
    };

    const setToken = (data) => {
        appDispatch({ type: "SET_TOKEN", data });
    };

    const setPayment = (data) => {
        appDispatch({ type: "SET_PAYEMENT", data });
    };

    const resetPayment = () => {
        appDispatch({ type: "RESET_PAYEMENT" });
    };

    const setLogo = (data) => {
        appDispatch({ type: "SET_LOGO", data });
    };

    const setEventImgs = (data) => {
        appDispatch({ type: "SET_EVENT_IMGS", data });
    };

    const setENV = (data) => {
        appDispatch({ type: "SET_ENV", data });
    };

    const setWidgetOPT = (data) => {
        appDispatch({ type: "SET_WIDGET_OPT", data });
    };

    const reset = () => {
        appDispatch({
            type: "RESET",
            data: {
                ...initAppData,
                env: appState.env,
                brandLogo: appState.brandLogo,
                isTesting: appState.isTesting,
            },
        });
    };

    return (
        <AppContext.Provider
            value={{
                app: {
                    ...appState,
                    setStep,
                    setInvoice,
                    setUser,
                    setToken,
                    setPayment,
                    reset,
                    setLogo,
                    setEventImgs,
                    setENV,
                    setWidgetOPT,
                    resetPayment,
                },
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export function useApp() {
    return useContext(AppContext);
}

export default AppContext;

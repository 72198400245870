export const TRANSLATIONS_FR = {
    getPaid: "Soyez payé dans le monde entier",
    moto: "Avec PayCruiser®, les entreprises peuvent accepter instantanément des paiements n'importe où, n'importe quand, sans avoir besoin des cartes de crédit ou des comptes bancaires de leurs utilisateurs. C'est rapide, simple, sécurisé et accessible.",
    continue: "Continuez",
    enter: "Saisissez votre identifiant de facturation ou votre code d'accès",
    invoice: "ID de facture ou code d'accès",
    next: "suivant",
    loading: "Chargement en cours",
    secure: "Page de paiement sécurisé",
    never: "Pour des raisons de sécurité renforcées, nous ne conservons jamais vos informations de paiement",
    howPay: "Comment voulez-vous payer?",
};

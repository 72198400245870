import React from "react";
import { WidgetHeader, WidgetBody, WidgetFooter } from "../../component/Widget";
import Button from "../../component/Button";
import Input from "../../component/Input";
// import styles from "./PaymentWidget.module.css";
import { toast, ToastContainer } from "react-toastify";
import "react-credit-cards/es/styles-compiled.css";
import "react-toastify/dist/ReactToastify.css";
import Cards from "react-credit-cards";
import axios from "axios";
import config from "../../configs";
import Alert from "../../component/Alert";
import PhoneInput from "react-phone-input-2";
import AppContext from "../../store/app-context";
import InputMask from "react-input-mask";

const initialCardData = {
    number: "",
    name: "",
    expiry: "",
    cvv: "",
    focused: "",
};

const initialFormState = {
    number: "",
    name: "",
    expiry: "",
    cvv: "",
    amount: "",
    zipcode: "",
    mobile: "",
    email: "",
    cardtype: "",
};

function getInitCardData(defaultOpt) {
    if (!defaultOpt) return initialCardData;
    if (defaultOpt?.user_card_no)
        initialCardData.number = defaultOpt?.user_card_no.value;
    if (defaultOpt?.user_card_exp_yr && defaultOpt?.user_card_exp_m)
        initialCardData.expiry = `${defaultOpt?.user_card_exp_yr.value}${defaultOpt?.user_card_exp_m.value}`;
    if (defaultOpt?.user_first_name && defaultOpt?.user_last_name)
        initialCardData.name = `${defaultOpt?.user_first_name.value} ${defaultOpt?.user_last_name.value}`;
    if (defaultOpt?.user_card_cvv)
        initialCardData.cvv = defaultOpt?.user_card_cvv.value;
    return initialCardData;
}

function getInitFormState(defaultOpt) {
    if (!defaultOpt) return initialFormState;

    const buildNewFormState = { ...initialFormState };
    if (defaultOpt?.user_card_no)
        buildNewFormState.number = defaultOpt?.user_card_no.value;
    if (defaultOpt?.user_card_exp_yr && defaultOpt?.user_card_exp_m)
        buildNewFormState.expiry = `${defaultOpt?.user_card_exp_yr.value}${defaultOpt?.user_card_exp_m.value}`;
    if (defaultOpt?.user_first_name && defaultOpt?.user_last_name)
        buildNewFormState.name = `${defaultOpt?.user_first_name.value} ${defaultOpt?.user_last_name.value}`;
    if (defaultOpt?.user_card_cvv)
        buildNewFormState.cvv = defaultOpt?.user_card_cvv.value;
    if (defaultOpt?.amount) buildNewFormState.amount = defaultOpt?.amount.value;
    if (defaultOpt?.user_mobile_no)
        buildNewFormState.mobile = defaultOpt?.user_mobile_no.value;
    return buildNewFormState;
}

function CreditCardPay() {
    const appCtx = React.useContext(AppContext);
    const {
        setStep,
        payment,
        invoice: invoiceInfo,
        resetPayment,
        reset,
        isTesting,
        widgetOpt,
    } = appCtx.app;

    const [form, setForm] = React.useState(getInitFormState(widgetOpt.prefill));
    const [formError, setFormError] = React.useState(initialFormState);
    const [cardInfo, setCardInfo] = React.useState(
        getInitCardData(widgetOpt.prefill)
    );
    const [isPaymentSuccess, setIsPaymentSuccess] = React.useState(false);
    const [paymentConfirmationCode, setPaymentConfirmationCode] =
        React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setForm((prevState) => {
            return {
                ...prevState,
                amount:
                    parseFloat(
                        widgetOpt.prefill.amount
                            ? widgetOpt.prefill.amount.value
                            : invoiceInfo?.invoice_amount
                    ) || 0,
            };
        });
    }, [invoiceInfo]);

    const setFormValue = (e) => {
        let newFormData;
        setForm((prevState) => {
            newFormData = {
                ...prevState,
                [e.target.name]: e.target.value,
            };
            onChnageValidate(newFormData);
            return newFormData;
        });

        setCardInfo({ ...cardInfo, [e.target.name]: e.target.value });
    };

    const validate = (newFormData = form) => {
        if (!newFormData.number) {
            setFormError((state) => ({
                ...state,
                number: "This field is required.",
            }));
            return false;
        } else if (newFormData.number.length > 16) {
            setFormError((state) => ({
                ...state,
                number: "Card number can't have more than 16 digit",
            }));
        }

        if (!newFormData.name) {
            setFormError((state) => ({
                ...state,
                name: "This field is required.",
            }));
            return false;
        } else if (form.name.trim().indexOf(" ") === -1) {
            setFormError((state) => ({ ...state, name: "Name is Not valid." }));
            return false;
        } else setFormError((state) => ({ ...state, name: "" }));

        if (parseFloat(invoiceInfo.amount_to_charge) < 0) {
            setFormError((state) => ({
                ...state,
                amount: "Amount must be greater than 0.",
            }));
            return false;
        } else setFormError((state) => ({ ...state, amount: "" }));

        if (!newFormData.expiry) {
            setFormError((state) => ({
                ...state,
                expiry: "Expiration date should be 4 digits (e.g: 1233)",
            }));
            return false;
        } else setFormError((state) => ({ ...state, expiry: "" }));

        if (!newFormData.cvv) {
            setFormError((state) => ({
                ...state,
                cvv: "Please insert your card cvv number.",
            }));
            return false;
        } else setFormError((state) => ({ ...state, cvv: "" }));

        // if (!newFormData.zipcode) {
        //   setFormError((state) => ({
        //     ...state,
        //     zipcode: "Please enter a zip code.",
        //   }));
        //   return false;
        // } else setFormError((state) => ({ ...state, zipcode: "" }));

        return true;
    };

    const onChnageValidate = (newFormData) => {
        if (newFormData.number)
            setFormError((state) => ({ ...state, number: "" }));
        if (newFormData.name) setFormError((state) => ({ ...state, name: "" }));
        if (newFormData.amount)
            setFormError((state) => ({ ...state, amount: "" }));
        if (newFormData.expiry)
            setFormError((state) => ({ ...state, expiry: "" }));
        if (newFormData.cvv) setFormError((state) => ({ ...state, cvv: "" }));
        if (newFormData.zipcode)
            setFormError((state) => ({ ...state, zipcode: "" }));
    };

    const paymentHandler = (paymentDetails) => {
        setLoading(true);
        const id = toast.loading("Please wait...");

        axios
            .post(`${config.baseUrl}/pay/`, paymentDetails)
            .then((res) => {
                if (res?.data?.payment_status)
                    showToast(`${res.data.payment_status}`);
                setLoading(false);

                if (res.data.status_code === 700) {
                    showToast(
                        "We could not process your transaction. If the issue persist, please call 562-999-2511 to complete your purchase."
                    );
                    return;
                }

                if (
                    Array.isArray(res.data.errors) &&
                    res.data.errors.length > 0
                ) {
                    res.data.errors.forEach((item, index) => {
                        showToast(`${item}`);
                    });
                } else {
                    if (
                        res.data.status_code === 250 ||
                        res.data.status_code === 200
                    ) {
                        setPaymentConfirmationCode(res.data.confirmation_code);
                        setIsPaymentSuccess(true);
                        toast.update(id, {
                            render: "Transaction is successful",
                            type: "success",
                            isLoading: false,
                            theme: "colored",
                        });

                        // TODO: Go confirmation Page
                    }
                }

                toast.update(id, { render: "", isLoading: false });
            })
            .catch((err) => {
                toast.update(id, {
                    render: `An error occured. CODE: ${err}`,
                    type: "error",
                    isLoading: false,
                    theme: "colored",
                });
                setLoading(false);
                // showToast(`An error occured. CODE: ${err}`);
                showToast(
                    `Please contact support to complete this purchase ${err}`
                );
            });
    };

    const handleSubmit = () => {
        if (!validate()) return;

        if (isTesting) {
            setIsPaymentSuccess(true);
            return;
        }

        // Reset error
        setFormError({
            number: "",
            name: "",
            expiry: "",
            cvv: "",
            amount: "",
            zipcode: "",
            mobile: "",
            email: "",
        });

        // separate month and year
        let spilt = form.expiry.match(/.{1,2}/g);
        let paymentDetails = {
            fullName: form.name.trim(),
            email: form.email,
            cardnum: form.number,
            cardtype: form.cardtype,
            cardmonth: spilt[0],
            cardyear: spilt[1],
            description: invoiceInfo.description || "",
            cardcvv: form.cvv,
            promocode: invoiceInfo.promocode,
            zipcode: form.zipcode,
            phone_number: form.phone_number || "",
            merchant_account: invoiceInfo.merchant.merchant_id,
            memo: invoiceInfo.memo,
            product_id: invoiceInfo.product_id || 999,
            unit_price: `${form.amount}`,
            unit_count: +invoiceInfo.unit_count || 1,
            tip: "0",
        };

        paymentHandler(paymentDetails);

        showToast("Processing Payment. PLEASE DO NOT REFRESH THE PAHE");
    };

    const showToast = (msg, type) => {
        toast(msg, {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type,
        });
    };

    return (
        <div className="widget-wrapper-slide-left">
            <WidgetHeader
                isBack={true}
                onBack={() => {
                    resetPayment();
                    setStep(3);
                }}
                title="Credit Card"
            />
            <WidgetBody>
                {/* For No Successfull Payment */}
                {!isPaymentSuccess && (
                    <>
                        <div
                            style={{ marginTop: "13rem", marginBottom: "1rem" }}
                        >
                            <Cards
                                number={cardInfo.number}
                                name={cardInfo.name}
                                expiry={cardInfo.expiry}
                                cvc={cardInfo.cvv}
                                focused={cardInfo.focused}
                                callback={(type) =>
                                    setForm((prevState) => ({
                                        ...prevState,
                                        cardtype: type.issuer,
                                    }))
                                }
                            />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <InputMask
                                mask="9999999999999999"
                                onChange={setFormValue}
                                value={form.number}
                                onFocus={(e) =>
                                    setCardInfo({
                                        ...cardInfo,
                                        focused: e.target.name,
                                    })
                                }
                            >
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        type="text"
                                        placeholder="Card number"
                                        name="number"
                                        helperText={formError.number}
                                        error={!!formError.number}
                                    />
                                )}
                            </InputMask>

                            <Input
                                placeholder="Enter cardholder name"
                                value={form.name}
                                name="name"
                                onChange={setFormValue}
                                helperText={formError.name}
                                error={!!formError.name}
                                onFocus={(e) =>
                                    setCardInfo({
                                        ...cardInfo,
                                        focused: e.target.name,
                                    })
                                }
                            />

                            <Input
                                placeholder="Amount to pay"
                                type="number"
                                value={form.amount}
                                name="amount"
                                disabled={
                                    widgetOpt?.prefill.amount.disabled || false
                                }
                                helperText={formError.amount}
                                error={!!formError.amount}
                                onChange={setFormValue}
                            />

                            <InputMask
                                mask="9999"
                                value={form.expiry}
                                onChange={setFormValue}
                                onFocus={(e) =>
                                    setCardInfo({
                                        ...cardInfo,
                                        focused: e.target.name,
                                    })
                                }
                            >
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        placeholder="Expiry"
                                        name="expiry"
                                        type="text"
                                        helperText={formError.expiry}
                                        error={!!formError.expiry}
                                    />
                                )}
                            </InputMask>

                            <InputMask
                                mask="999"
                                onChange={setFormValue}
                                onFocus={(e) =>
                                    setCardInfo({
                                        ...cardInfo,
                                        focused: e.target.name,
                                    })
                                }
                                value={form.cvv}
                            >
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        placeholder="cvv"
                                        name="cvv"
                                        helperText={formError.cvv}
                                        error={!!formError.cvv}
                                    />
                                )}
                            </InputMask>

                            {/* <Input
                                    placeholder="Zipcode"
                                    type="number"
                                    value={form.zipcode}
                                    name="zipcode"
                                    helperText={formError.zipcode}
                                    error={!!formError.zipcode}
                                    onChange={setFormValue}
                                /> */}

                            {/* <PhoneInput
                                    style={{boxShadow: "3px 5px 15px #082ba824", borderBottom: "1px solid #777", marginBottom: "1rem"}}
                                    countryCodeEditable={true}
                                    inputStyle={{fontFamily: "-apple-system", height: "3rem", border: "none" }}
                                    name="mobile"
                                    type="tel"
                                    country={"sn"}
                                    value={form.mobile}
                                    onChange={(e) => setForm((prevState) => ({ ...prevState, mobile: e.target.value}))}
                                    // Set your inline styles here
                                    containerStyle={{
                                        display: "flex",
                                        borderBottom: "1px solid grey",
                                    }}
                                    // style={{ zIndex: 1 }}
                                    dropdownStyle={{ border: "none", textAlign: "start" }}
                                    buttonStyle={{ border: "none" }}
                                    /> */}

                            {/* <Input
                                    placeholder="Mobile for receipt"
                                    value={form.mobile}
                                    name="mobile"
                                    helperText={formError.mobile}
                                    error={!!formError.mobile}
                                    onChange={setFormValue}
                                /> */}

                            <Input
                                placeholder="Email for receipt"
                                value={form.email}
                                name="email"
                                helperText={formError.email}
                                error={!!formError.email}
                                onChange={setFormValue}
                            />
                        </form>
                    </>
                )}

                {/* For No Successfull Payment */}
                {isPaymentSuccess && (
                    <>
                        <img
                            height={100}
                            width={100}
                            src="https://storage.googleapis.com/paycruiser/PayCruiser_Success.gif"
                            alt="success gif"
                            style={{ padding: "5rem" }}
                        />
                        <Alert severity="success">
                            {`Your Payment of $${form.amount} was successful. Confirmation Code: ${paymentConfirmationCode}`}
                        </Alert>
                    </>
                )}
            </WidgetBody>
            <WidgetFooter>
                <ToastContainer />
                {!isPaymentSuccess && (
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Pay {form.amount} XOF
                    </Button>
                )}

                {!!isPaymentSuccess && (
                    <Button color="primary" fullWidth onClick={() => reset()}>
                        New Payment
                    </Button>
                )}
            </WidgetFooter>
        </div>
    );
}

export default CreditCardPay;

import React from "react";
import styles from "./Input.module.css";

function Input(props) {
    const {
        type = "text",
        placeholder,
        onBlur,
        onClick,
        helperText,
        error,
        ...rest
    } = props;

    return (
        <div className={styles.controlWrapper}>
            <div className={styles.InputWrapper}>
                <input
                    type={type}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onClick={onClick}
                    className={`${styles.Input} ${error ? styles.invalid : ""}`}
                    {...rest}
                />
            </div>
            {!!helperText && (
                <p
                    className={`
              ${styles.HelperText} 
              ${error ? styles.txtError : ""}
              `}
                >
                    {helperText}
                </p>
            )}
        </div>
    );
}

export default Input;

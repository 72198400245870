import React, { useState, useContext } from "react";
import { WidgetBody, WidgetFooter, WidgetHeader } from "../../component/Widget";
import AppContext from "../../store/app-context";
import Input from "../../component/Input";
import Button from "../../component/Button";
import axios from "axios";
import config from "../../configs";
import Alert from "../../component/Alert";
import Dialog from "../../component/Dialog";

/**
 *
 * @param {string} iframeUrl
 * @returns
 */
function HostedPayment() {
    const appCtx = React.useContext(AppContext);
    const {
        setStep,
        resetPayment,
        invoice: invoiceInfo,
        widgetOpt,
    } = appCtx.app;
    const { merchant } = invoiceInfo;
    const [errorMsg, setErrMsg] = useState("");
    const [amount, setAmount] = useState(
        parseFloat(
            widgetOpt.prefill.amount
                ? widgetOpt.prefill.amount.value
                : invoice_amount
        ) || 0
    );
    const [hostedPaymentUrl, setHostedPaymentUrl] = useState();

    const payemntHandler = () => {
        const payload = {
            fullName: "paycruiser 3ds",
            email: merchant.email,
            cardnum: "9111111111111111",
            cardtype: "3ds",
            cardmonth: "01",
            cardyear: "99",
            description: `Invoice code: ${invoiceInfo.access_code}`,
            cardcvc: "123",
            promocode: "",
            zipcode: "",
            phone_number: "",
            merchant_account: merchant.merchant_id,
            memo: invoiceInfo.access_code,
            product_id: 999,
            unit_price: `${amount}`,
            unit_count: 1,
            tip: "0",
            currency_code: merchant.user_currency,
        };

        axios
            .post(`${config.baseUrl}/pay/widget-payment/nosec/`, payload)
            .then((res) => {
                console.log(res.data);
                if (res.data.is_success || res.data.is_success === "true") {
                    setHostedPaymentUrl(res.data.redirect_url);
                }
                if (Array.isArray(res.data.errors) && res.data.errors.length) {
                    console.log(res.data.errors[0][0]);
                    setErrMsg(res.data.errors[0][0]);
                }
            })
            .catch((err) => console.log);
    };

    return (
        <>
            <WidgetHeader
                isBack={true}
                onBack={() => {
                    resetPayment();
                    setStep(3);
                }}
                title="Credit Card"
            />
            <WidgetBody>
                {!hostedPaymentUrl && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Input
                            placeholder={"Amount"}
                            value={amount}
                            // error={invoiceError}
                            // helperText={"Enter your amount"}
                            onBlur={(e) => setAmount(e.target.value)}
                            onChange={(e) => setAmount(e.target.value)}
                            style={{
                                fontSize: "4.5rem",
                                border: "none",
                                boxShadow: "none",
                                textAlign: "center",
                            }}
                        />

                        <h3>Please confirm your amount.</h3>
                        <p style={{ textAlign: "center" }}>
                            Once confirmed you will be redirected to secured
                            page.
                        </p>
                    </div>
                )}
                {hostedPaymentUrl && !!amount && (
                    <iframe
                        height="100%"
                        width="100%"
                        src={hostedPaymentUrl}
                        style={{ border: "none" }}
                    ></iframe>
                )}
            </WidgetBody>
            {!hostedPaymentUrl && (
                <WidgetFooter>
                    <Button color="primary" fullWidth onClick={payemntHandler}>
                        Confirm
                    </Button>
                </WidgetFooter>
            )}

            {errorMsg && (
                <Dialog>
                    <h3 style={{ textAlign: "center" }}>Error</h3>
                    <br></br>
                    {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    <br></br>
                    <Button
                        onClick={() => {
                            setErrMsg("");
                            setHostedPaymentUrl("");
                        }}
                    >
                        Okay
                    </Button>
                </Dialog>
            )}
        </>
    );
}

export default HostedPayment;

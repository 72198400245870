import React from "react";
import { createPortal } from "react-dom";
import BackDrop from "../Backdrop";
import styles from "./Modal.module.css";

const body = document.getElementsByTagName("BODY")[0];

function Modal({ children }) {
    const elRef = React.useRef(null);
    if (!elRef.current) {
        elRef.current = document.createElement("div");
        elRef.current.classList.add("modalContainer");
        elRef.current.setAttribute("id", "paycrusier-modal");
    }

    React.useEffect(() => {
        body.appendChild(elRef.current);
        return () => body.removeChild(elRef.current);
    }, []);

    return createPortal(
        <>
            <BackDrop></BackDrop>
            <div id="modal" className={styles.Modal}>
                <div id="modal-inner" className={styles.ModalInner}>
                    {children}
                </div>
            </div>
        </>,
        elRef.current
    );
}

export default Modal;

import devConfig from "./dev";
import testConfrig from "./test";

const env =
    "development" || process.env.NODE_ENV || process.env.REACT_APP_HOST_ENV;
const baseUrl =
    "https://api.paycruiser.ai" || process.env.REACT_APP_API_BASEURL;

const baseConfig = {
    isDev: env === "development" || env === "dev",
    isTest: env === "testing" || env === "test",
    baseUrl,
    port: process.env.PORT || 3000,
};

let envConfig = {};

switch (env) {
    case "dev":
    case "development":
        envConfig = { ...baseConfig, ...devConfig };
        break;
    case "test":
    case "testing":
        envConfig = { ...baseConfig, ...testConfrig };
        break;
    default:
        envConfig = { ...baseConfig, ...devConfig };
}

export default envConfig;

import React, { useEffect, useState } from "react";
import PaymentWidget from "./pages/PaymentWidget";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import appContext from "./store/app-context";
import useQuery from "./hooks/useQuery";
import TestMode from "./pages/TestMode";
import Dialog from "./component/Dialog";
import Button from "./component/Button";
import FlyerCarousel from "./pages/PaymentWidget/FlyerCarousel";

function App() {
    const appCtx = React.useContext(appContext);
    const {
        isTesting,
        setStep,
        setWidgetOPT,
        setLogo,
        setEventImgs,
        eventImgs,
    } = appCtx.app;
    const [showEventImgs, setShowEventImgs] = useState(false);
    const query = useQuery();

    useEffect(() => {
        console.log({ eventImgs });
        if (eventImgs.length) setShowEventImgs(true);
    }, [eventImgs]);

    useEffect(() => {
        let widgetOpt = {};
        if (query.get("security_code")) {
            widgetOpt["security_code"] = query.get("security_code");
        }

        // for (const key of query.keys()) {
        //     widgetOpt[key] = query.get(key);
        // }

        if (query.get("opt")) {
            widgetOpt = JSON.parse(window.atob(query.get("opt")));
            console.log({ widgetOpt });

            if (Object.keys(widgetOpt).length) setWidgetOPT(widgetOpt);

            if (widgetOpt?.brand_logo) {
                setLogo(widgetOpt?.brand_logo);
            }

            if (widgetOpt?.event_img) {
                setEventImgs(widgetOpt?.event_img);
                const img = Array.isArray(widgetOpt?.event_img)
                    ? widgetOpt?.event_img[0]
                    : widgetOpt?.event_img;
                document
                    .querySelectorAll("meta[property=og\\:image]")[0]
                    .setAttribute("content", img);
                document
                    .querySelectorAll("meta[property=twitter\\:image]")[0]
                    .setAttribute("content", img);
            }
        }

        if (widgetOpt?.security_code) {
            setWidgetOPT(widgetOpt);
            setStep(2);
        }
    }, [query]);

    return (
        <>
            <Router>
                <Switch>
                    <Route path="/" exact>
                        <PaymentWidget />
                    </Route>
                    <Route path="/invoice/:id" exact>
                        <PaymentWidget />
                    </Route>
                    <Route path="/invoice" exact>
                        <PaymentWidget />
                    </Route>
                </Switch>
            </Router>
            {!!isTesting && <TestMode />}

            {/* {showEventImgs && (
                <Dialog className="event-flyer-imgs">
                    <FlyerCarousel />
                    <Button
                        color="primary"
                        fullWidth
                        onClick={() => setShowEventImgs(false)}
                    >
                        Close
                    </Button>
                </Dialog>
            )} */}
        </>
    );
}

export default App;

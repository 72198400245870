import React, { useState } from "react";
import Button from "../Button";
import styles from "./WidgetHeader.module.css";
import { useApp } from "../../store/app-context";
import Dialog from "../Dialog";

function WidgetHeader(props) {
    const [showDialog, setShowDialog] = useState(false);
    const { isBack = false, onBack, title = "PayCruiser®" } = props;
    const { app } = useApp();

    const closeHandler = () => {
        const paycruiserWidget = document.getElementById("paycrusier-modal");
        paycruiserWidget.classList.add("fade-out-animate");

        setTimeout(() => {
            paycruiserWidget.classList.remove("fade-out-animate");
            setShowDialog(false);
            const message = JSON.stringify({
                message: JSON.stringify({
                    event: "WIDGET_CLOSE",
                    data: { paymentStatus: app.payment.status },
                }),
                date: Date.now(),
            });
            window.parent.postMessage(message, "*");
        }, 500);
    };

    return (
        <div className={styles.HeaderWrapper}>
            {isBack && (
                <Button className={styles.backBtn} iconBtn onClick={onBack}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        width="48"
                    >
                        <path d="M18 38 4 24 18 10 20.1 12.1 9.7 22.5H44V25.5H9.7L20.1 35.9Z" />
                    </svg>
                </Button>
            )}
            <h3 className={`${styles.title} ${isBack ? styles.noMargin : ""}`}>
                {title}
            </h3>
            <Button
                className={`${styles.closeBtn} widget-close`}
                iconBtn
                onClick={() => setShowDialog(true)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                    <path d="M12.45 37.65 10.35 35.55 21.9 24 10.35 12.45 12.45 10.35 24 21.9 35.55 10.35 37.65 12.45 26.1 24 37.65 35.55 35.55 37.65 24 26.1Z" />
                </svg>
            </Button>

            {showDialog && (
                <Dialog>
                    <h3>Close Widget</h3>
                    <hr />
                    <p>Confirm?</p>
                    <br></br>
                    <Button onClick={closeHandler}>Yes, close</Button>
                    <Button onClick={() => setShowDialog(false)}>No</Button>
                </Dialog>
            )}
        </div>
    );
}

export default WidgetHeader;

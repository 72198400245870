export const TRANSLATIONS_EN = {
    getPaid: "Businesses get paid worldwide",
    moto: "With PayCruiser®, businesses can instantly accept payments anywhere, anytime, without requiring their users' credit cards or bank accounts. It's fast, simple, secured and accessible.",
    continue: "Continue",
    enter: "Your invoice ID or access code",
    invoice: "Enter here...",
    next: "Next",
    loading: "Loading",
    secure: "Secured Payment Page",
    never: "For enhanced security reason, we never store your payment details",
    howPay: "How would you like to pay?",
};

import React from "react";
import styles from "./Button.module.css";

function Button(props) {
    const {
        onClick,
        className,
        style,
        children,
        color,
        iconBtn = false,
        fullWidth = false,
        size = "small",
        ...rest
    } = props;

    return (
        <button
            onClick={onClick}
            className={`
            ${fullWidth ? styles.fullWidth : ""}
            ${!iconBtn && styles.Button} 
            ${className ? className : ""} 
            ${color === "primary" ? styles.primaryBtn : ""}
            ${
                size === "small"
                    ? styles.ButtonSmall
                    : size === "large"
                    ? styles["ButtonLarge"]
                    : styles["ButtonMedium"]
            }}    
            ${styles.ButtonBase} `}
            style={{ ...style }}
            {...rest}
        >
            {children}
        </button>
    );
}

export default Button;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_FR } from "./French/Translation";
import { TRANSLATIONS_EN } from "./English/Translation";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            "en-US": {
                translation: TRANSLATIONS_EN,
            },
            "fr-FR": {
                translation: TRANSLATIONS_FR,
            },
        },
    });

// i18n.changeLanguage("en");
i18n.changeLanguage(window.navigator.language || "fr-FR");

export default i18n;

import React from "react";
import AppContext from "../../store/app-context";
import CreditCardPay from "./CreditCardPay";
import MobilePay from "./MobilePay";
import PaycruiserPayment from "./PaycruiserPayment";
import PaymentChoose from "./PaymentChoose";
import HostedPayment from "./HostedPayment";

function Pay() {
    const appCtx = React.useContext(AppContext);
    const { payment, invoice } = appCtx.app;
    const { type = null } = payment;
    console.log(invoice?.merchant.user_currency);

    return (
        <>
            {!type && <PaymentChoose />}

            {type === "CARD" && invoice?.merchant.user_currency != "XOF" && (
                <CreditCardPay paymentTitle="Credit Card" />
            )}

            {type === "CARD" && invoice?.merchant.user_currency === "XOF" && (
                <HostedPayment />
            )}

            {type === "MOBILE" && <MobilePay />}

            {type === "PAYCRUISER" && <PaycruiserPayment />}
        </>
    );
}

export default Pay;
